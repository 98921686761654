export default {
  ShopProfile: {
    _tag: 'CSidebarNavItem',
    name: 'Restaurant Profile',
    phrase: 'Restaurant Profile',
    to: '/restaurant-profile',
    order: 1,
    icon: 'fas fa-store',
    roles: ['vendor'],
  },
}
