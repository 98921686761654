export default [
  {
    name: 'dashboard',
    component: require('../modules/dashboard'),
    status: true,
    order: 1,
  },

  {
    name: 'Vendors',
    component: require('../modules/vendors'),
    status: true,
    order: 1,
  },
  {
    name: 'Users',
    component: require('../modules/users'),
    status: true,
    order: 1,
  },

  {
    name: 'Category',
    component: require('../modules/categories'),
    status: true,
    order: 1,
  },

  {
    name: 'Coupons',
    component: require('../modules/coupons'),
    status: true,
    order: 1,
  },
  {
    name: 'Restaurants',
    component: require('../modules/restaurants'),
    status: true,
    order: 1,
  },
  {
    name: 'ImageSlider',
    component: require('../modules/image_slider'),
    status: true,
    order: 1,
  },
  {
    name: 'notification',
    component: require('../modules/Notifications'),
    status: true,
    order: 1,
  },
  {
    name: 'language',
    component: require('../modules/Language'),
    status: true,
    order: 1,
  },
  {
    name: 'terms&polices',
    component: require('../modules/termandpolices'),
    status: true,
    order: 1,
  },
  {
    name: 'ShopProfile',
    component: require('../modules/ShopProfile'),
    status: true,
    order: 1,
  },
  {
    name: 'superadmin',
    component: require('../modules/superadmin'),
    status: true,
    order: 1,
  },
]
