import React from 'react'
const Terms = React.lazy(() => import('./components/Term&Conditions'))
const Policy = React.lazy(() => import('./components/Policy'))

const routes = {
    terms: {
        path: '/terms-and-conditions',
        exact: true,
        component: Terms,
    },
    policy: {
        path: '/privacy-policy',
        exact: true,
        component: Policy,
    },
}

export default routes
