export default {
  Users: {
    _tag: 'CSidebarNavItem',
    name: 'Users',
    phrase: 'Users',
    to: '/dummy',
    icon: 'fa-solid fa-users-gear',
    roles: ['admin','Super Admin'],
    order:2,
    children: {
      vendors_list: {
        _tag: 'CSidebarNavItem',
        name: 'vendors_list',
        phrase: 'Vendors List',
        to: '/vendors',
        order: 1,
        icon: 'fa-regular fa-rectangle-history-circle-user',
        roles: ['admin','Super Admin'],
      },
    },
  },
}
