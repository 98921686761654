import React, { useState, useEffect } from "react";
import Api from "@evenlogics/whf-api";
import StatisticsCard from "./StatisticsCard";
import { Row, Col, Container } from "react-bootstrap";
import Chart from "./Chart";
import BarChart from "./BarChart";
const Dashboard = () => {




  const [counts, setCounts] = useState({

  });
  const [isAuthorized, setisAuthorized] = useState(false);
  useEffect(() => {
    const intervelID = setInterval(() => {
      let currentUser = localStorage.getItem("currentUser");

      try {
        currentUser = JSON.parse(currentUser);
        if (currentUser?.roles[0] !== "admin" || currentUser?.roles[0] !== "Super Admin") {
          Api.request("get", "/statistics").then((response) => {
            console.log(response);

            setTimeout(() => {
              setCounts(response.data);
            }, 1500);
          });
          clearInterval(intervelID);

          setisAuthorized(true);
        }
      } catch {
        console.log("parse failed");
      }
    }, 1000);
  }, []);

  return (
    <div>
      {isAuthorized ? (
        <Container fluid={true}>
          <Row className="gy-4">
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.users}
                icon="fa fa-users"
                name="Registered Users"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.vendor}
                icon="fa-solid fa-user-tie-hair"
                name="Registered Vendor"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.online_users}
                icon="fas fa-user-plus"
                name="Online Users"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.redeemed_coupons}
                icon=" fas fa-tag  "
                name="Redeem Coupons"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.coopons_by_vendors}
                icon=" fa fa-tag "
                name="Coupons by Vendors"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.shop_visits}
                icon="fa-solid fa-shop "
                icon2="fa-solid fa-person-walking"
                name="Restaurant  Visitors"
              />
            </Col>
            <Col xxl="3" xl="4" md="6" xs={12}>
              <StatisticsCard
                count={counts?.banner_clicks}
                icon="fa-solid fa-panorama"
                name="Banner Clicks"
              />
            </Col>
          </Row>

          <Row className="g-4 mt-5  mb-5">


            <Col md={6} xxl={3} xl={4} xs={12}
            >
              <div className="card h-100">
                <div className="card-header bg-transparent">
                  <h4 className="mb-0 text-dark">Age %</h4>
                </div>
                <div className="card-body ">
                  <Chart className="placeholder-wave" chartData={counts?.age_percentage} />
                </div>
              </div>
            </Col>
            <Col md={6} xxl={3} xl={4} xs={12}
            >
              <div className="card h-100 ">
                <div className="card-header bg-transparent">
                  <h4 className="mb-0 text-dark">Gender %</h4>
                </div>
                <div className="card-body ">
                  <Chart className="placeholder-wave" chartData={counts?.gender_percentage} />
                </div>
              </div>
            </Col>
            <Col md={6}  xs={12}>

              <div className="card  h-100">
                <div className="card-header bg-transparent">
                  <h4 className="mb-0 text-dark">Cities %</h4>
                </div>
                <div className="card-body ">
                  <BarChart redeemed_city={counts?.redeemed_city_count} />
                </div>
              </div>


            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
};

export default Dashboard;
