import React, { useState, useEffect } from "react";
import ShopCard from "./ShopCard";
const RestaurantProfile = (props) => {
  const [isAuthorized, setisAuthorized] = useState(false);
  const [vendorID, setVendorID] = useState();

  useEffect(() => {
    const intervelID = setInterval(() => {
      let currentUser = localStorage.getItem("currentUser");

      try {
        currentUser = JSON.parse(currentUser);
        if (currentUser.roles[0] === "vendor") {
          setVendorID(currentUser.id);
          setisAuthorized(true);

          clearInterval(intervelID);
        }
      } catch {
        console.log("parse failed");
      }
    }, 1000);
  }, [isAuthorized]);
  return (
    <>
      {isAuthorized ? (
        <ShopCard admin={false} path={"/shops?user_id=" + vendorID} />
      ) : (
        ""
      )}
    </>
  );
};

export default RestaurantProfile;
