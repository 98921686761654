import React from 'react'
const restaurantProfile = React.lazy(() =>
  import('./components/RestaurantProfile')
)

const routes = {
  ShopProfile: {
    path: '/restaurant-profile',
    exact: true,
    component: restaurantProfile,
    roles: ['vendor'],
  },
}

export default routes
