export default {
  Categories: {
    _tag: 'CSidebarNavItem',
    name: 'Categories',
    phrase: 'Categories',
    to: '/categories',
    icon: 'fa-solid fa-list-tree',
    roles: ['admin','Super Admin'],
    order:4
  },
}
