export default {
  dashboard: {
    _tag: 'CSidebarNavItem',
    name: 'dashboard',
    phrase: 'dashboard',
    to: '/dashboard',
    order: 0,
    icon: 'fa-solid fa-chart-line',
    roles: ['admin','Super Admin'],
  },
}
