export default {
  Shops: {
    _tag: 'CSidebarNavItem',
    name: 'Shops',
    phrase: 'Shops',
    to: '/shops',
    order: 6,
    icon: 'fa-solid fa-shop',
    roles: ['admin','Super Admin'],
  },
}
