import React from 'react'
const AddCoupon = React.lazy(() => import('./components/CreateCoupon'))
const CouponsList = React.lazy(() => import('./components/CouponsList'))

const routes = {
    AddCoupon: { path: '/add-coupon', exact: true, component: AddCoupon },
    EditCoupon: {
        path: '/coupons/:id/edit',
        exact: true,
        component: AddCoupon,
    },
    CouponsList: { path: '/coupons', exact: true, component: CouponsList },
}

export default routes
