import React from 'react'
const UsersList = React.lazy(() => import('./components/UsersList'))
const UserDetail = React.lazy(() =>
  import('./../vendors/components/VendorDetail')
)

const routes = {
  UsersList: { path: '/users', exact: true, component: UsersList },
  UserDetail: {
    path: '/users/:id/details',
    exact: true,
    component: UserDetail,
  },
}

export default routes
