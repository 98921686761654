export default {
  language: {
    _tag: 'CSidebarNavItem',
    name: 'language',
    phrase: 'Language',
    to: '/language',
    icon: 'fa-solid fa-language',
    roles: ['admin','Super Admin'],
    order:9
  },
}
