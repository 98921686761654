export default {
  termAndPolices: {
    _tag: "CSidebarNavItem",
    name: "Terms and Polices",
    phrase: "Settings",
    to: "/dummy",
    order: 9,
    icon: 'fa-solid fa-folder-gear',
    roles: ['admin','Super Admin'],
    children: {
      terms: {
        _tag: "CSidebarNavItem",
        name: "terms-and-conditions",
        phrase: "Terms & Conditions",
        to: "/terms-and-conditions",
        order: 1,
        icon: "fa-regular fa-circle-exclamation-check",
      },
      policy: {
        _tag: "CSidebarNavItem",
        name: "privacy-policy",
        phrase: "privacy Policy",
        to: "/privacy-policy",
        order: 2,
        icon: "fa-regular fa-route-interstate",
      },
    },
  },
};
