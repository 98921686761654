export default {
  Notifications: {
    _tag: 'CSidebarNavItem',
    name: 'notifications',
    phrase: 'Notifications',
    to: '/notifications',
    order: 8,
    icon: 'fa-solid fa-bell-on',
    roles: ['admin','Super Admin'],
  },
}
