import customNav from "./config/nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Styles.scss";
import Api from "@evenlogics/whf-api";
Api.apiUrl = "https://bidi.code-weaver.io/api/v1";
global.customNavGlobal = customNav;
global.customRoutes = require("./config/routes");
global.customModules = require("./config/modules");
global.customLogin = false;
global.customNavElements = false;
require(`@evenlogics/whf-reactadmin`);
