import React from "react";
const NotificationList = React.lazy(() => import("./Views/NotificationList"));
const Notification = React.lazy(() => import("./Views/Notification"));

const routes = {
  Notification: {
    path: "/notifications/add-notification",
    exact: true,
    component: Notification,
    roles: ['admin','Super Admin'],
  },
  NotificationEdit: {
    path: "/push-notifications/:id/edit",
    exact: true,
    component: Notification,
    roles: ['admin','Super Admin'],
  },
  NotificationsList: {
    path: "/notifications",
    exact: true,
    component: NotificationList,
    roles: ['admin','Super Admin'],
  },
};

export default routes;
