import React from 'react'
import { FormGenerator } from '@evenlogics/whf-form-generator'
import { Card } from 'react-bootstrap'

const ChangePassword = (props) => {
  const { id } = props.match.params
  let fields = {
    heading:{
      type: 'h4',
      name:'Language',
      col: 12,

    },
    current_password: {
      type: 'password',
      label: 'Current Password',
      required: id ? false : true,
      name: 'current_password',
      col:' col-lg-4 col-md-6 ',
      placeholder: 'Enter current password',
      className: 'form-control-lg',
      autoComplete:"new-password"
    },
    new_password: {
        type: 'password',
        label: 'New Password',
        required: id ? false : true,
        name: 'new_password',
        col:' col-lg-4 col-md-6 ',
        placeholder: 'Enter new password',
        className: 'form-control-lg',
        autoComplete:"new-password"
      },
    password_confirmation: {
      type: 'password',
      label: 'Confirm Password',
      required: id ? false : true,
      name: 'password_confirmation',
      col:' col-lg-4 col-md-6 ',
      placeholder: 'Confirm Password ',
      className: 'form-control-lg',
      oneOf: 'new_password',
      autoComplete:"new-password"
    },
  }

  return (
      <Card className="animated fadeIn globalCard">
        <Card.Header >
            <h5 className="mb-0"> Update Password </h5>
        </Card.Header>
        <Card.Body>
          <FormGenerator
            targetEntity={`change_password`}
            fields={fields}
            targetId={id}
            name="change_password"
            // getInitialValues={this.getInitialValues}
            // debug={false}
            redirect={'dashboard'}
            // repeater={true}
          />
        </Card.Body>
      </Card>
  )
}

export default ChangePassword
