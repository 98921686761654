import React from 'react'
const SliderList = React.lazy(() => import('./components/SlidersList'))
const ImageSlider = React.lazy(() => import('./components/ImageSlider'))

const routes = {
  SliderList: {
    path: '/banners',
    exact: true,
    component: SliderList,
    roles: ['admin','Super Admin'],
  },
  ImageSlider: {
    path: '/add-banner',
    exact: true,
    component: ImageSlider,
    roles: ['admin','Super Admin'],
  },
  ImageSliderEdit: {
    path: '/banners/:id/edit',
    exact: true,
    component: ImageSlider,
    roles: ['admin','Super Admin'],
  },
}

export default routes
