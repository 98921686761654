export default {
  Coupons: {
    _tag: 'CSidebarNavItem',
    name: 'Coupons',
    phrase: 'Coupons',
    to: '/coupons',
    order: 5,
    icon: 'fa-duotone fa-gift-card',
  },
}
