import React from 'react'
const AddAdmin = React.lazy(() => import('./components//AddAdmin'))
const AdminList = React.lazy(() => import('./components/AdminList'))

const routes = {
    AddAdmin: { path: '/super-admins/add-admin', exact: true, component: AddAdmin },
    EditAdmin: {
        path: '/super-admins/:id/edit',
        exact: true,
        component: AddAdmin,
    },
    AdminList: {
        path: '/super-admins',
        exact: true,
        component: AdminList,
    },
}

export default routes
