import React from "react";
const Language = React.lazy(() => import("./Views/SelectLang"));

const routes = {
  language: {
    path: "/language",
    exact: true,
    component: Language,
    roles: ['admin','Super Admin'],
  },
};

export default routes;
