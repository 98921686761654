 export const customStyles =  {

        control: (base, state) => ({
            ...base, minHeight: "52px", maxWidth: '353px',
            boxShadow: '0 5px 5px rgba(0, 0, 0, 7%)', borderRadius: '7px', fontSize: '16px',
            border: state.isFocused ? "1px solid #00a1ed" : "1px solid #e4e4e4",
            ':hover': {
                border: '1px solid #00a1ed'
            }
        }),

        menu: (menu) => ({ ...menu, maxWidth: '353px' }),
        valueContainer: (valueContainer) => ({ ...valueContainer, flexWrap: 'nowrap', overflowX: 'auto',color: '#5c6873' }),
        multiValue: (multiValue) => ({ ...multiValue, flex: '1 0 auto',color: '#5c6873' }),
        singleValue : (value) => ({ ...value, color: '#5c6873'}),
        multiValueLabel : (valueLabel) => ({ ...valueLabel , color: '#5c6873'})
    }
