import React, { useState, useEffect } from "react";
import Api from "@evenlogics/whf-api";
import { Row, Col, Card, Form, ListGroup, Image , Spinner} from "react-bootstrap";
import Select from "react-select";
import "./restaurantProfile.scss";
import moment from "moment";
import {customStyles} from "./../../SelectStyle"
import { Link } from 'react-router-dom';

const ShopCard = ({ admin, path }) => {
  const [restaurant, setRestaurant] = useState();
  const [placeholder, setPlaceholder] = useState("placeholder-wave bg-light");
  const [shopsList, setShops] = useState([]);
  const [loader ,setLoader] = useState(true)

  useEffect(() => {
    Api.request("get", path).then((response) => {
      console.log(response);
      if (admin) {
        setRestaurant(response.data);
      } else {
        setRestaurant(response.data[0]);
        setShops(
          response.data.map((shop) => {
            return { value: shop.id, label: shop.title };
          })
        );
      }
      setLoader(false)
      setTimeout(() => {
        setPlaceholder("");
      }, 1000);
    });
  }, [admin, path]);

  const handleSelectChange = (e) => {
    console.log(e);
    Api.request("get", "/shops/" + e.value).then((res) => {
      console.log(res.data);
      setRestaurant(res.data);
    });
  };
  return (
    <Card className={admin ? "bg-transparent shadow-none" : ""}>
      <Card.Body>
        {!admin ? (
          <Row>
            <Form.Group
              as={Col}
              lg="6"
              xxl="4"
              controlId="Categories"
              className="form-group ms-auto"
            >
              <Form.Label>Select Shop </Form.Label>

              <Select
                key={shopsList}
                defaultValue={[shopsList[0]]}
                options={shopsList}
                styles={customStyles}
                name="categories"
                className={placeholder}
                onChange={(e) => handleSelectChange(e)}
              ></Select>
            </Form.Group>
          </Row>
        ) : (
          ""
        )}
        <Row>
          { loader ? <div className="text-center py-5 my-5">
            <Spinner animation="border" />
          </div> :<Col md="8" lg="7" xl="6" xxl="6" className="mx-auto">
           {restaurant?.shop_banner ? <Card className="d-flex flex-row resCard">
              <div className="coverImage">
                <div className="mask"></div>
                <Image
                  src={restaurant?.shop_banner?.url}
                  className={placeholder}
                  alt=""
                />

                <Image
                  src={restaurant?.shop_logo?.url}
                  alt=""
                  className={`shopLogo ${placeholder}`}
                />
              </div>
              <Card.Body>
                <h1 className={`shopName ${placeholder}`}>{restaurant?.title}</h1>

                <Card.Text className={`location ${placeholder}`}>
                  <a href={`tel:${restaurant?.phone}`}>{restaurant?.phone}</a>
                </Card.Text>

                <Card.Text className={`location ${placeholder}`}>
                  <a href={`mailto:${restaurant?.email}`}>
                    {restaurant?.email}
                  </a>
                </Card.Text>

                <Card.Text className={`location ${placeholder}`}>
                  <a href={`${restaurant?.website}`} rel="noopener noreferrer" target="_blank">
                    {restaurant?.website}
                  </a>
                </Card.Text>

                <Card.Text className={`location ${placeholder}`}>
                  Category : {restaurant?.categories?.title}
                </Card.Text>

                <Card.Text className={`location ${placeholder}`}>
                  {restaurant?.location}
                </Card.Text>

                <Card.Text className={`location ${placeholder}`}>
                  <span className="badge bg-dark">
                    <span className="fw-bold">Open At:</span>{" "}
                    {moment(restaurant?.open_at, "h:mm a").format("h:mm a")}{" "}
                  </span>
                  &nbsp;&nbsp;
                  <span className="badge bg-dark">
                    <span className="fw-bold">Close At:</span>
                    {moment(restaurant?.close_at, "h:mm a").format("h:mm a")}
                  </span>
                </Card.Text>
                <hr />

                <ListGroup variant="flush">
                  <ListGroup.Item className={`${placeholder}`}>
                    Total Coupons :{" "}
                    <span className="badge bg-dark">
                      {restaurant?.coupons?.length}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item className={`${placeholder}`}>
                    License No :{" "}
                    <span className="badge bg-dark">
                      {restaurant?.license_no}
                    </span>
                  </ListGroup.Item>

                  <ListGroup.Item className={`${placeholder}`}>
                    License Expiry :{" "}
                    <span className="badge bg-dark">
                      {restaurant?.license_expiry}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
                <div className="py-3 text-center">
                  <Link to={`/shops/${restaurant.id}/edit?vendor=true`} rel="noopener noreferrer" className={`${placeholder} btn btn-info`}>Edit Shop</Link>
                </div>
              </Card.Body>
            </Card> : <div className={`noShop text-center py-5 my-5 border rounded-pill shadow-sm ${admin ? 'd-none' : ''}`}>
                   <h4><i className="fa-solid fa-shop"></i> You Have No Shop </h4>
            </div> }
          </Col>}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ShopCard;
