import React from 'react'
import { Card, Placeholder } from 'react-bootstrap'
import './Card.scss'
import Curve from './../../../assets/images/curve.png'
const StatisticsCard = (props) => {
  return (
    <Card className="statisticsCard">
      <Card.Body>
        <div className="d-flex">
          <div className="textDiv text-center">
            <small>{props.name}</small>
            {props.count !== undefined ? (
              <h3>{props.count}</h3>
            ) : (
              <Placeholder
                as="h3"
                className="img-fluid"
                animation="wave"
                bg="dark"
              />
            )}
          </div>
          <img src={Curve} alt="curve" />
          <div className="Icon">
            <i className={props.icon}></i>
            <i className={props?.icon2}></i>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default StatisticsCard
