import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, defaults } from 'chart.js';
import { Pie, } from 'react-chartjs-2';
import { Spinner, } from 'react-bootstrap'
const Chart = (props) => {
  console.log(props?.chartData)
  const [values, setValues] = React.useState([])
  const [label, setLabel] = React.useState([])
  const [sum, setSum] = React.useState(Number)
  ChartJS.register(ArcElement, Tooltip, Legend);
  defaults.color = '#333'
  defaults.fillText = "hello"
  defaults.labels = { "hello": "world" }
  React.useEffect(() => {

    const points = []
    const lab = []

    if (props?.chartData) {
      console.log(Object.values(props?.chartData))
      const values = Object.values(props?.chartData)
      const sum = values.reduce((a, b) => a + b)
      console.log(sum)
      setSum(sum)
      Object.entries(props?.chartData).forEach(([key, val]) => {
        points.push(parseFloat(val).toFixed(2))
        lab.push(key)
      })
    }

    setValues(points)
    setLabel(lab)
  }, [props.chartData])

  const data = {
    labels: label,
    datasets: [
      {
        label: "  ",
        data: values,
        backgroundColor: [
          'rgba(255, 99, 132, 0.7)',
          'rgba(54, 162, 235, 0.7)',
          'rgba(255, 206, 86, 0.7)',
          'rgba(75, 192, 192, 0.7)',
          'rgba(153, 102, 255, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 5,
        color: "#fff"
      },
    ],

  };

  return (
    props.chartData ? <div className="position-relative">
      {sum > 0 ? <Pie data={data} /> : <p ><b>No data for you today!</b></p>} </div>
      : <div className={"py-5 text-center my-5"}> <Spinner animation="grow" variant="dark" size="lg" /></div>
  )
}

export default Chart