export default {
  ImageSlider: {
    _tag: 'CSidebarNavItem',
    name: 'ImageSlider',
    phrase: 'Promotional Banners',
    to: '/banners',
    order: 7,
    icon: 'fa-regular fa-panorama',
    roles: ['admin','Super Admin'],
  },
}
