import vendor from './../modules/ShopProfile/components/RestaurantProfile'
import admin from './../modules/dashboard/components/Dashboard'
import PasswordChange from './../modules/ChangePassword'
const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: admin,
    isPublic: false,
    exact: true,
    roles: ['admin',"Super Admin"],
  },
  {
    path: '/dashboard',
    name: 'resturant-profile',
    component: vendor,
    isPublic: false,
    exact: true,
    roles: ['vendor'],
  },
  {
    path: '/',
    name: 'resturant-profile',
    component: vendor,
    isPublic: false,
    exact: true,
    roles: ['vendor'],
  },
  {
    path: '/',
    name: 'dashboard',
    component: admin,
    isPublic: false,
    exact: true,
    roles: ['admin','Super Admin'],
  },
  {
    path: '/change-password',
    name: 'password',
    component: PasswordChange,
    isPublic: false,
    exact: true,
    roles: ['admin','Super Admin'],
  },
]

export default routes
