export default {
  admin: {
    _tag: 'CSidebarNavItem',
    name: 'admin',
    phrase: 'Super Admin',
    to: '/super-admins',
    icon: 'fa-solid fa-rectangle-history-circle-user',
    roles: ['admin','Super Admin'],
    order:2
  },
}
