import React from 'react'
const AddCategory = React.lazy(() => import('./components/AddCategory'))
const CategoriesList = React.lazy(() => import('./components/CategoriesList'))

const routes = {
    AddCategory: { path: '/add-category', exact: true, component: AddCategory },
    Editcategories: {
        path: '/categories/:id/edit',
        exact: true,
        component: AddCategory,
    },
    CategoriesList: {
        path: '/categories',
        exact: true,
        component: CategoriesList,
    },
}

export default routes
