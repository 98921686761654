// import React from 'react'
// const dashboard = React.lazy(() => import('./components/Dashboard'))

const routes = {
  // dashboard: {
  //   path: '/dashboard',
  //   exact: true,
  //   component: dashboard,
  //   roles: ['admin'],
  // },
}

export default routes
