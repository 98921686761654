import React from 'react'
const AddVendors = React.lazy(() => import('./components/AddVendor'))
const VendorsList = React.lazy(() => import('./components/VendorsList'))
const VendorsDetail = React.lazy(() => import('./components/VendorDetail'))
const VendorsPass = React.lazy(() => import('./components/Password'))

const routes = {
  AddVendors: {
    path: '/vendor-add',
    exact: true,
    component: AddVendors,
    roles: ['admin','Super Admin'],
  },
  VendorsList: {
    path: '/vendors',
    exact: true,
    component: VendorsList,
    roles: ['admin','Super Admin'],
  },
  EditVendor: {
    path: '/vendors/:id/edit',
    exact: true,
    component: AddVendors,
    roles: ['admin','Super Admin'],
  },
  EditPassword: {
    path: '/vendors/:id/password',
    exact: true,
    component: VendorsPass,
    roles: ['admin','Super Admin'],
  },
  VendorsDetail: {
    path: '/vendors/:id/details',
    exact: true,
    component: VendorsDetail,
    roles: ['admin','Super Admin'],
  },
}

export default routes
