export default {
	password: {
		_tag: 'CSidebarNavItem',
		name: 'Change Password',
		phrase: 'Change Password',
		to: '/change-password',
		order: 100,
		icon: 'fa-solid fa-key',
		roles: ['admin','Super Admin'],
		badge: {
			color: 'info',
			text: 'NEW'
		}
	}
};