import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Spinner, } from 'react-bootstrap'
const BarChart = (props) => {

  const [series, setSeries] = useState([])
  const [categories, setcategories] = useState([])
  const [sum, setSum] = React.useState(Number)

  React.useEffect(() => {

    const categories = []
    const series = []

    if (props?.redeemed_city) {
      let cites = {}
      let object = props?.redeemed_city.forEach(item => {
        cites = { ...cites, [Object.keys(item)[0]]: item[Object.keys(item)[0]] }
      });
      object = cites;
      const values = Object.values(object)
      const sum = values.reduce((a, b) => a + b)
      console.log(sum, values, "sum")
      setSum(sum)
      Object.entries(object).forEach(([key, val]) => {
        console.log(key, val)
        categories.push(key)
        series.push(parseFloat(val).toFixed(2))
      })
    }

    setcategories(categories)
    setSeries(series)
  }, [props.redeemed_city])


  const seriesList = [{ data: series }]
  const options = {
    chart: {
      type: 'bar', height: 350, animation: true, toolbar: {
        show: false
      },
    },
      plotOptions: {
        bar: {
          borderRadius: 0, horizontal: true, dataLabels: {
            position: 'top'
          }
        }
      },
      xaxis: {
        categories: categories,
        position: "bottom",
        labels: {
          show: false,
          offsetY: 0
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
      },

      title: {
        text: "Jordan Top 10 Cities",
        floating: true,
        offsetY: 0,

        align: "center",
        style: {
          color: "#444",
          fontSize: "20px",
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: 40,
        Position: 'outside bar edge',
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
    }


  return(
      props.redeemed_city?.length > 0 ?
        <div className="position-relative">
          {sum > 0 ?
            <ReactApexChart options={options} series={seriesList} type='bar' height={350} />
            :
            <p ><b>No data for you today!</b></p>}
        </div>
        :
        <div className={"py-5 text-center my-5"}>
          <Spinner animation="grow" variant="dark" size="lg" />
        </div>
  )
}

export default BarChart