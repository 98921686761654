import React from "react";
const AddRestaurant = React.lazy(() => import("./components/AddRestaurant"));
const RestaurantsList = React.lazy(() => import("./components/RestrauntsList"));
const RestaurantDetail = React.lazy(() =>
  import("./components/RestaurantDetail")
);

const routes = {
  AddRestaurant: {
    path: "/add-shop",
    exact: true,
    component: AddRestaurant,
    roles: ['admin','Super Admin'],
  },
  EditRestaurant: {
    path: "/shops/:id/edit",
    exact: true,
    component: AddRestaurant,
    roles: ['admin','Super Admin','vendor'],
  },
  RestaurantsList: {
    path: "/shops",
    exact: true,
    component: RestaurantsList,
    roles: ['admin','Super Admin'],
  },
  RestaurantDetail: {
    path: "/shops/:id/details",
    exact: true,
    component: RestaurantDetail,
    roles: ['admin','Super Admin'],
  },
};

export default routes;
